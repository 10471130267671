const Footer = () => {
  return (
    <>
      <div className="footer">
        <p className="text-footer">
          &copy;crypto-app-2023 by@YACINE-Ahmed-Adel
        </p>
      </div>
    </>
  );
};

export default Footer;
